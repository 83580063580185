// const logigloUrl = 'http://localhost:3001/api'  

// const logigloUrl = 'http://3.84.145.255/api'

// const logigloUrl = 'http://logiglo.com/api'
// const logigloUrl = 'http://test.logiglo.com/api'


export const constants = {
    env:{
        testIp:'test.logiglo.com',
        prodIp:'logiglo.com',
        local : 'http://localhost:3001/api', 
        test:'http://test.logiglo.com/api',
        prod:'https://logiglo.com/api',
        testBaseUrl:'test.logiglo.com',
        prodBaseUrl:'logiglo.com'
    }
}                
   
const env = 'prod';     
                
// const env = 'local'; 
 
// IP : 35   

// git   
       
            
                 

export const baseUrlIP = env === 'prod' ? constants.env.prodIp : constants.env.testIp  
// export const logigloUrl = env === 'prod' ? constants.env.prod : constants.env.test  
export const logigloUrlBase = env === 'prod' ? constants.env.prodBaseUrl : constants.env.testBaseUrl  

export const logigloUrl =
  env === 'prod'
    ? constants.env.prod
    : env === 'test'   
    ? constants.env.test 
    : constants.env.local;

   

      

     



   



         



